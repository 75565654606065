import Header from '../Header'
import Paragraph from '../Paragraph'
import { Row, Col, Container } from 'react-bootstrap'
import Button from '../Button'

import Monthly from '../../img/Monthly.svg'
import Hand from '../../img/Hand.svg'

import './Kickback.css'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const Kickbacks = () => {
  return (
    <Container className="custom-container my-5">
      <Row className="pt-5 d-flex justify-content-center">
        <Col sm={6} xl={3} className="kickback-card-wrapper">
          <div className="frankly-card text-center kickback-card">
            <div className="icon-container">
              <Header fontSize="40px" color="primary" className="bold m-auto">
                15%
              </Header>
            </div>
            <div className="kickback-card-box white-card">
              <Paragraph fontSize="20px" color="primarytextcolor" className="bold mb-1">
                Kickback
              </Paragraph>
              <Paragraph size="p3" color="primarytextcolor" className="paragraph-muted mb-0 fw-light">
                {/* You receive  15% of <br/> all sold coverages */}
                Du modtager 15% af <br /> alle solgte forsikringer
              </Paragraph>
            </div>
          </div>
        </Col>
        <Col sm={6} xl={3} className="kickback-card-wrapper">
          <div className="frankly-card text-center kickback-card">
            <div className="icon-container">
              <LazyLoadImage src={Monthly} alt="Monthly" className="m-auto" height={80} width={80} />
            </div>
            <div className="kickback-card-box white-card">
              <Paragraph fontSize="20px" color="primarytextcolor" className="bold mb-1">
                {/* Monthly Payouts */}
                Gratis 1. måned
              </Paragraph>
              <Paragraph size="p3" color="primarytextcolor" className="paragraph-muted mb-0 fw-light">
                {/* You receive your due <br/> payout each month. */}
                Tilbyd dine kunder 1<br /> måneds gratis dækning
              </Paragraph>
            </div>
          </div>
        </Col>
        <Col sm={6} xl={3} className="kickback-card-wrapper">
          <div className="frankly-card text-center kickback-card">
            <div className="icon-container">
              <LazyLoadImage src={Hand} alt="Hand" className="m-auto" height={80} width={80} />
            </div>
            <div className="kickback-card-box white-card">
              <Paragraph fontSize="20px" color="primarytextcolor" className="bold mb-1">
                {/* No Risk */}
                Ingen risiko
              </Paragraph>
              <Paragraph size="p3" color="primarytextcolor" className="paragraph-muted mb-0 fw-light">
                {/* We run the entire<br/> service and support.   */}
                Vi står for alt support
                <br /> og administration
              </Paragraph>
            </div>
          </div>
        </Col>
        <Col sm={6} xl={3} className="kickback-card-wrapper">
          <div className="frankly-card text-center kickback-card">
            <div className="icon-container">
              <Header fontSize="40px" color="primary" className="bold m-auto">
                {/* 0 days */}0 dage
              </Header>
            </div>
            <div className="kickback-card-box white-card">
              <Paragraph fontSize="20px" color="primarytextcolor" className="bold mb-1">
                {/* No Binding */}
                Ingen Binding
              </Paragraph>
              <Paragraph size="p3" color="primarytextcolor" className="paragraph-muted mb-0 fw-light">
                {/* You are free to cut the<br/> ties at any time. */}
                Du er fri til at opsige <br /> til enhver tid
              </Paragraph>
            </div>
          </div>
        </Col>
      </Row>
      <span className="d-flex justify-content-center kickback-button pt-2 pt-sm-5">
        <Button onClick={() => (window.location.href = '#signup')}>
          {/* Get me started */}
          Bliv kontaktet
        </Button>
      </span>
    </Container>
  )
}

export default Kickbacks
