import './Hero.css'

import { Container, Row, Col } from 'react-bootstrap'

import Header from '../Header'
import Paragraph from '../Paragraph'
import Button from '../Button'

import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/black-and-white.css'
import Sofa from '../../img/Sofa.png'
import Toggle from '../../img/Toggle.svg'
import NavLogo from '../../img/NavLogo.svg'
import MaxWidthWrap from '../MaxWidthWrap'

const Hero = ({ header, paragraph }) => {
  return (
    <Container className="custom-container my-xl-5 my-2">
      <Row className="pt-3">
        <Col
          xl={7}
          className="order-last order-xl-first hero-col-left d-flex flex-column d-md-block justify-content-center"
        >
          <Header size="h5" color="primary" className="hero-header fw-light mb-4 mt-4">
            {/* <span className="fw-bold">Smart insurance </span><br/> for e-commerces */}
            <span className="fw-bold">Intelligent forsikring </span>
            <br /> til din butik og kunder
          </Header>
          <MaxWidthWrap width={561} className="mb-4">
            <Paragraph size="p3" className="paragraph-muted fw-light">
              Tilføj Frankly Insure til checkout og giv dine shoppere muligheden for at beskytte deres køb.
              <br />
              <br />
              Ingen implementering. Ingen skjulte gebyrer. Med vores plug-in, kan du være oppe at køre på 1-2 timer.
              {/* Add Frankly Insure to checkout and give shoppers the opportunity to protect their purchases. 
              <br/>
              <br/>
              Zero implementation, zero fees. Only pure kick-back. With our plug-in, you can be up an running in less than 1 hour.  */}
            </Paragraph>
            <Button signUp className="mt-4 mt-sm-5 px-4 d-block ms-0 me-auto mx-xl-0">
              Bliv kontaktet
            </Button>
          </MaxWidthWrap>
        </Col>
        <Col
          xl={{ span: 4, offset: 0 }}
          className="order-first order-xl-last hero-col-right d-flex d-lg-block flex-column"
        >
          <div className="hero-product p-3 pb-2 pb-sm-3 m-sm-0">
            <div className="product-image-container mt-0">
              <LazyLoadImage
                alt="BILLEDE"
                src={Sofa}
                effect="black-and-white"
                className="d-block mx-auto product-image"
              />
            </div>
            <div className="product-info">
              <Header size="h3" color="primary" className="hero-header mb-2">
                Paula, 3 prs. sofa
              </Header>
              <Header size="price-header" color="primary" className="hero-header mb-3">
                8.999 kr.
              </Header>
              <div className="frankly-info d-flex justify-content-between align-items-center w-100">
                <div className="d-inline-flex align-items-center">
                  <img src={Toggle} alt="Toggle" className="toggle-icon m-0 p-0" />
                  <Paragraph fontSize="13px" className="paragraph-muted bold ps-2 pt-1">
                    {/* All Risk Coverage */}
                    All Risk Forsikring
                  </Paragraph>
                </div>
                <div className="d-none d-sm-inline-flex align-items-center">
                  <div className="frankly-product-logo d-flex">
                    <img src={NavLogo} alt="Frankly Logo" className="" />
                  </div>
                  <Paragraph fontSize="13px" className="paragraph-muted d-block ps-2 pt-1">
                    <span className="bold">Frankly</span> Insure
                  </Paragraph>
                </div>
                <div className="d-inline-flex align-items-center">
                  <Paragraph fontSize="13px" className="paragraph-muted bold pt-1">
                    DKK 21
                  </Paragraph>
                  <Paragraph fontSize="10px" className="paragraph-muted pt-1 pe-2">
                    {/* /Monthly */}
                    /md.
                  </Paragraph>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default Hero
