import 'bootstrap/dist/css/bootstrap.min.css'
import './global.css'
import './ma.css'
import './App.css'

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Home from './components/pages/Home'
import Business from './components/pages/Business'
import MobilePaySuccess from './components/pages/MobilePaySuccess'
import MobilePayCancel from './components/pages/MobilePayCancel'
import Contact from './components/pages/Contact'
import Claims from './components/pages/Claims'
import Terms from './components/pages/Terms'
import Conditions from './components/pages/Conditions'
import PdfHandler from './components/pages/PdfHandler'
import Redirect from './components/pages/Redirect'
import ScrollToTop from './components/utils/ScrollToTop'

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/business" element={<Business />} />
        <Route path="/mobilepay-success" element={<MobilePaySuccess />} />
        <Route path="/mobilepay-cancel" element={<MobilePayCancel />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/claims" element={<Claims />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/conditions" element={<Conditions />} />
        <Route path="/pdf/policy" element={<Redirect to="/conditions" />} />
        <Route path="/pdf/policy/:category" element={<PdfHandler />} />
        <Route path="/pdf/ipid" element={<PdfHandler fileName="IPID_Wakam_Frankly_ADT_Danish_130125.pdf" />} />

        {/* Danish routes */}
        <Route path="/hjem" element={<Home />} />
        <Route path="/business" element={<Business />} />
        <Route path="/kontakt" element={<Contact />} />
        <Route path="/anmeld-skade" element={<Claims />} />
        <Route path="/vilk%C3%A5r" element={<Terms />} />
        <Route path="/betingelser" element={<Conditions />} />
        <Route path="/priser" element={<PdfHandler fileName="Priser.pdf" />} />

        {/* 404 */}
        <Route path="*" element={<div>Not Found</div>} />
      </Routes>
    </Router>
  )
}

export default App
