import { useState } from 'react'
import './ClaimsInput.css'

const FileInput = ({ inputName, id, placeholder, submission, multiple, error }) => {
  const [fileList, setFileList] = useState([])

  const handleFileChange = (e) => {
    const chosenFiles = Array.from(e.target.files)
    setFileList(chosenFiles)
    submission(e)
  }

  const removeFile = (indexToRemove) => {
    const newFiles = fileList.filter((_, index) => index !== indexToRemove)
    setFileList(newFiles)

    // Create a new event-like object to pass to submission
    const syntheticEvent = {
      target: {
        name: inputName,
        files: newFiles,
        type: 'file',
      },
    }
    submission(syntheticEvent)
  }

  return (
    <>
      <input
        name={inputName}
        id={id}
        className="hidden-file-input"
        type="file"
        onChange={handleFileChange}
        multiple={multiple}
      />
      <label
        htmlFor={id}
        className={error ? 'claims-input custom-file-input error-state' : 'claims-input custom-file-input'}
      >
        {fileList.length === 0 ? placeholder || 'Choose file(s)' : fileList.map((f) => f.name).join(', ')}
      </label>
      {multiple && fileList.length > 0 && (
        <div className="file-list">
          {fileList.map((file, index) => (
            <div key={index} className="file-item">
              <span>{file.name}</span>
              <button type="button" className="remove-file" onClick={() => removeFile(index)}>
                ×
              </button>
            </div>
          ))}
        </div>
      )}
    </>
  )
}

// Modified ClaimsInput component
const ClaimsInput = ({
  name,
  inputName,
  placeholder,
  submission,
  type = 'text', //Change this to textarea to increase height and put input at the top of its container!
  toggle = null,
  multiple = false,
  essential = false,
  error = false,
}) => {
  const id = name.replace(/\s/g, '')
  const [textArea, setTextArea] = useState('')
  const [slider, setSlider] = useState(true)

  const handleTextArea = (e) => {
    setTextArea(e.target.value || '')
    submission(e)
  }

  const handleSlider = (e) => {
    setSlider(!slider)
    if (toggle !== null) {
      toggle(e)
    } else {
      submission(e)
    }
  }

  const renderInput = () => {
    switch (type) {
      case 'slider':
        return (
          <div className="d-flex">
            <label className="frankly-switch">
              <input
                name={inputName}
                id={name}
                className="frankly-radio-btn"
                type="checkbox"
                value={slider}
                onChange={handleSlider}
              />
              <span className="frankly-slider"></span>
            </label>
            <p className="slider-paragraph">{placeholder}</p>
          </div>
        )
      case 'file':
        return (
          <FileInput
            inputName={inputName}
            id={id}
            placeholder={placeholder}
            submission={submission}
            multiple={multiple}
            error={error}
          />
        )
      case 'textarea':
        return (
          <textarea
            name={inputName}
            id={id}
            className={error ? 'claims-input error-state' : 'claims-input'}
            placeholder={placeholder}
            value={textArea || ''}
            onChange={handleTextArea}
          />
        )
      default:
        return (
          <input
            name={inputName}
            id={id}
            className={error ? 'claims-input error-state' : 'claims-input'}
            type={type}
            placeholder={placeholder}
            onChange={submission}
          />
        )
    }
  }

  return (
    <div className="claims-input-wrap my-4">
      <label htmlFor={id} className="claims-input-label">
        {name}
        {essential && <span className="red-star">*</span>}
      </label>
      {renderInput()}
    </div>
  )
}

export default ClaimsInput
