import { Container } from 'react-bootstrap'
import MpSuccess from '../page-components/MpSuccess'

const MobilePaySuccess = () => {
  return (
    <Container className="overflow-hidden" fluid>
      <MpSuccess />
    </Container>
  )
}

export default MobilePaySuccess
