/* eslint-disable jsx-a11y/anchor-is-valid */
import SectionWrapper from '../SectionWrapper'
import MaxWidthWrap from '../MaxWidthWrap'
import Header from '../Header'
import Paragraph from '../Paragraph'

import './MpSuccess.css'

const MpSuccess = () => {
  return (
    <SectionWrapper className="d-block mp-wrap">
      <Header size="h4" className=" mp-header">
        Du er nu forsikret hos{' '}
        <a href="https://www.franklyinsure.dk/" target="_blank" className="bold muted" rel="noreferrer">
          Frankly Insure
        </a>
        .
      </Header>
      <MaxWidthWrap className="mp-wrap" width={600}>
        <Paragraph className="pt-4" size="p3">
          Tillykke, og velkommen i familien hos Frankly Insure. Du har truffet et smart valg ved at beskytte dig selv og
          dine produkter med vores dækning. Her er hvad du behøver at vide:
        </Paragraph>

        <Header className="pt-4" size="h2">
          Detaljer vedrørende din forsikring:
        </Header>
        <Paragraph className="pt-4" size="p3">
          Din police er aktiv med det samme og dækker alle punkter i henhold til den plan, du har valgt.{' '}
          <a href="#">Klik her</a> for at se detaljerne i din police.
        </Paragraph>

        <Header className="pt-4" size="h2">
          Din police nummer:
        </Header>
        <Paragraph className="pt-4" size="p3">
          Notér venligst dit police nummer. Du kan finde det i den email, vi har sendt til dig.
        </Paragraph>

        <Header className="pt-4" size="h2">
          Claim Process:
        </Header>
        <Paragraph className="pt-4" size="p3">
          If you ever need to make a claim, don't worry. We're here to make the process as simple and speedy as
          possible. You can read about our quick claim process <a href="#">here</a>.
        </Paragraph>

        <Header className="pt-4" size="h2">
          Kundesupport:
        </Header>
        <Paragraph className="pt-4" size="p3">
          Vores dedikerede kundeservice-team er her for dig 24/7. Du kan til enhver tid kontakte os via e-mail, telefon
          eller live chat.
        </Paragraph>
      </MaxWidthWrap>
      <Paragraph className="pt-4" size="p3">
        Brug også et øjeblik på at udforske vores <a href="#">ofte stillede spørgsmål</a> for at få flere oplysninger.
      </Paragraph>
    </SectionWrapper>
  )
}

export default MpSuccess
