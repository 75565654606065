import './MaxWidthWrap.css'

const MaxWidthWrap = ({ children, width, center, className }) => {
  let centered = ''
  if (center) centered = 'max-width-center'
  if (!className) className = ''
  return (
    <div className={centered + className} style={{ maxWidth: width }}>
      {children}
    </div>
  )
}

export default MaxWidthWrap
