import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import './AlertDialog.css'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/black-and-white.css'
import Danger from '../../img/Danger.svg'

const AlertDialog = (props) => {
  const [show, setShow] = useState(false)

  useEffect(() => {
    setShow(Object.entries(props.errorMessages).length !== 0)
  }, [props.errorMessages])

  return (
    <Modal
      {...props}
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      closeButton
      backdrop={false}
      keyboard={true} // Allow keyboard interactions
      enforceFocus={false}
      backdropClassName="b-drop"
      onHide={() => {
        setShow(false)
      }}
    >
      <Modal.Header closeButton>
        <div className="d-flex align-items-center">
          <LazyLoadImage alt="BILLEDE" src={Danger} effect="black-and-white" />
          <h4 className="fw-medium">Fejl</h4>
        </div>
      </Modal.Header>
      <Modal.Body>
        <p>
          {Object.entries(props.errorMessages).map(([key, value], index) => (
            <div className="fw-medium" key={index}>
              {value}
              <br />
            </div>
          ))}
        </p>
      </Modal.Body>
    </Modal>
  )
}
export default AlertDialog
