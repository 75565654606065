// Import react
import { Navbar, Container, Nav } from 'react-bootstrap'

import './NavBar.css'
import LogoTransparent from '../../img/NavLogo.svg'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import Button from '../Button'
import { Link } from 'react-router-dom'

const NavBar = ({ navArray = [] }) => {
  const navDropdownElements = navArray.map((item, index) => (
    <Link className={`nav-link mt-3 mt-lg-auto my-lg-auto ${item.active ? 'active' : ''}`} key={index} to={item.link}>
      {item.name}
    </Link>
  ))

  return (
    <Navbar bg="white" variant="light" expand="lg">
      <Container className="custom-container">
        <div className="navbar-left d-flex align-items-center">
          <div className="navbar-logo">
            <Link to="/">
              <LazyLoadImage alt="Logo" src={LogoTransparent} height="24px" className="navbar-img m-auto" />
            </Link>
          </div>
          <Link className="ps-4 pt-1 fw-light" to="/">
            <span className="fw-semibold">Frankly</span> Insure
          </Link>
        </div>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="">
            {navDropdownElements}
            <Button className="mt-3 mt-lg-auto my-lg-auto" signUp>
              Sign up
            </Button>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default NavBar
