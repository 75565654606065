import 'react-lazy-load-image-component/src/effects/black-and-white.css'

import NavBar from '../page-components/NavBar'
import Footer from '../page-components/Footer'
import HeroBusiness from '../page-components/HeroBusiness'
import Partners from '../page-components/Partners'
import Different from '../page-components/Different'
import Kickback from '../page-components/Kickback'
import Numbers from '../page-components/Numbers'
import Integration from '../page-components/Integration'
import Steps from '../page-components/Steps'
import Signup from '../page-components/Signup'

const Business = () => {
  return (
    <>
      <NavBar
        navArray={[
          { name: 'Hjem', link: '/' },
          { name: 'For butikker', link: '/business', active: true },
        ]}
      />

      <HeroBusiness />

      <Partners />

      <Different />

      <Kickback />

      <Numbers />

      <Integration />

      <Steps />

      <Signup />

      <Footer />
    </>
  )
}

export default Business
