import './Paragraph.css'

const Paragraph = ({ size, children, className, fontSize }) => {
  className = className ? className : ''
  let sizes = ['p1', 'p2', 'p3', 'p4', 'p5', 'p6', 'p7', 'paragraph-muted']
  if (sizes.includes(size)) return <p className={'paragraph-component ' + size + ' ' + className}>{children}</p>
  else if (fontSize)
    return (
      <p className={'paragraph-component ' + className} style={{ fontSize: fontSize }}>
        {children}
      </p>
    )
  else return <p>No paragraph size selected.</p>
}

export default Paragraph
