import './Hero.css'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Header from '../Header'
import Paragraph from '../Paragraph'
import Button from '../Button'

import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/black-and-white.css'
import MacBook from '../../img/macbook.svg'
import NavLogo from '../../img/NavLogo.svg'
import Toggle from '../../img/Toggle.svg'
import MaxWidthWrap from '../MaxWidthWrap'
import { Container } from 'react-bootstrap'

const Hero = () => {
  return (
    <Container className="my-xl-5 my-2 hero-section custom-container justify-content-center justify-content-xl-start">
      <Row className="pt-3">
        <Col
          xl={7}
          className="order-last order-xl-first hero-col-left d-flex flex-column d-md-block justify-content-center"
        >
          <Header size="h5" color="primary" className="hero-header fw-light mb-4 mt-4 mt-md-2">
            {/* <span className="fw-bold">Cover your online purchases </span>in one click  */}
            <span className="fw-bold">Slip bekymringerne </span> tilføj forsikring når du shopper online
          </Header>
          <MaxWidthWrap width={561} className="mb-4">
            <Paragraph size="p3" className="fw-light">
              {/* From gaming rigs to living room <br className='d-sm-none'/> chandeliers, we've got your back. <br className='d-sm-none'/> Insurance? Frankly, it's never been simpler.  */}
              En kaffemaskine. Sofa. Eller! En ny Macbook? Tilføj forsikring til en fast lav månedlig pris ved checkout.
              <br />
              <br />
              {/* Don't let worries ruin your online shopping spree. Add Frankly Insure to your cart, and secure your purchase with a one-time payment or spread it out monthly.           */}
              Ingen binding. Nul gebyrer. Kan opsiges til enhver tid. <br />
              Det er kundeservice, der vil noget.
            </Paragraph>
            <Button signUp className="mt-4 mt-sm-5 px-4 d-block ms-0 me-auto mx-xl-0">
              Sign up
            </Button>
          </MaxWidthWrap>
        </Col>
        <Col
          xl={{ span: 4, offset: 0 }}
          className="order-first order-xl-last hero-col-right d-flex d-lg-block flex-column"
        >
          <div className="hero-product p-3 pb-2 pb-sm-3 m-sm-0">
            <div className="product-image-container mt-0">
              <LazyLoadImage
                alt="BILLEDE"
                src={MacBook}
                effect="black-and-white"
                className="d-block mx-auto product-image"
              />
            </div>
            <div className="product-info">
              <Header size="h3" color="primary" className="hero-header mb-2">
                Macbook Pro 2023
              </Header>
              <Header size="price-header" color="primary" className="hero-header mb-3">
                17.999 kr.
              </Header>
              <div className="frankly-info d-flex justify-content-between align-items-center w-100">
                <div className="d-inline-flex align-items-center">
                  <img src={Toggle} alt="Toggle" className="toggle-icon m-0 p-0" />
                  <Paragraph fontSize="13px" className="paragraph-muted fw-medium ps-2 pt-1">
                    {/* All Risk Coverage */}
                    All Risk Forsikring
                  </Paragraph>
                </div>
                <div className="d-none d-sm-inline-flex align-items-center">
                  <div className="frankly-product-logo d-flex">
                    <img src={NavLogo} alt="Frankly Logo" className="" />
                  </div>
                  <Paragraph fontSize="13px" className="paragraph-muted d-block ps-2 pt-1">
                    <span className="bold">Frankly</span> Insure
                  </Paragraph>
                </div>
                <div className="d-inline-flex align-items-center">
                  <Paragraph fontSize="13px" className="paragraph-muted bold pt-1">
                    DKK 38
                  </Paragraph>
                  <Paragraph fontSize="10px" className="paragraph-muted pt-1 pe-2">
                    /md.
                    {/* /Monthly */}
                  </Paragraph>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default Hero
