import 'react-lazy-load-image-component/src/effects/black-and-white.css'

import NavBar from '../page-components/NavBar'
import Footer from '../page-components/Footer'
import Hero from '../page-components/Hero'
import Collage from '../page-components/Collage'
import Flow from '../page-components/Flow'
import AppSection from '../page-components/AppSection'
import Signup from '../page-components/Signup'

const Home = () => {
  return (
    <>
      <NavBar
        navArray={[
          { name: 'Hjem', link: '/' },
          { name: 'For butikker', link: '/business' },
        ]}
      />

      <Hero />

      <Collage />

      <Flow />

      <AppSection />

      <Signup />

      <Footer />
    </>
  )
}

export default Home
