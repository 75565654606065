import './Signup.css'
import { Row, Col, Container } from 'react-bootstrap'
import Header from '../Header'
import Paragraph from '../Paragraph'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/black-and-white.css'
import './AppSection.css'
import woman from '../../img/signup.webp'
import Button from '../Button'
import axios from 'axios'
import { useState } from 'react'

const Signup = () => {
  const [status, setStatus] = useState('')
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [terms, setTerms] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)

  const handleSignup = (e) => {
    e.preventDefault()
    if (isSubmitted) return
    setStatus('Sender...')
    setIsSubmitted(true)

    if (!terms) {
      setStatus('Giv dit samtykke for at fortsætte.')
      setIsSubmitted(false)
      return
    }

    if (!name || !email) {
      setStatus('Navn og email skal udfyldes.')
      setIsSubmitted(false)
      return
    }

    //Make req object to send to backend
    const req = {
      name: name,
      email: email,
    }

    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      withCredentials: false,
    }

    //Send req to backend
    //console.log(process.env.REACT_APP_API_URL+'/signup');
    axios
      .post(process.env.REACT_APP_API_URL + '/signup', req, config)
      .then((res) => {
        console.log(res)
        setStatus('Tak for at skrive dig op. Dine informationer er nu gemt.')
        setIsSubmitted(true)
      })
      .catch((err) => {
        console.log(err)
        setStatus('Noget gik galt. Prøv igen.')
        setIsSubmitted(false)
      })
  }
  return (
    <Container className="custom-container">
      <Row id="signup" className="px-0 pb-6 pt-sm-6">
        {/* Desktop */}
        <Col className="d-none d-lg-block px-0">
          <div className="signup-container d-flex flex-row">
            <LazyLoadImage
              alt="BILLEDE"
              src={woman}
              effect="black-and-white"
              className="signup-image"
              width={407}
              height={470}
            />
            <div className="pt-5 ps-5 ps-lg-6">
              <Header className="fw-light" size="h4" color="primary">
                <span className="fw-bold">Frankly</span> Sign Up.
              </Header>
              <Paragraph size="p3" className="py-2 fw-light">
                {/* Become a partner today! */}
                Shop mere med Frankly Insure.
              </Paragraph>
              <form className="signup-form" onSubmit={handleSignup}>
                <input
                  className="signup-text-input"
                  placeholder="Navn"
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <input
                  className="signup-text-input"
                  placeholder="Email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <br />
                <input
                  className="signup-checkbox"
                  type="checkbox"
                  checked={terms}
                  onChange={(e) => setTerms(e.target.checked)}
                />
                <label className="py-3 ms-2 signup-checkbox-label" htmlFor="terms">
                  Jeg giver samtykke{' '}
                  <a className="p2" target="_blank" href="/terms">
                    {'(Betingelser og vilkår)'}
                  </a>
                </label>
                <br />
                <Paragraph size="p3" className="paragraph-muted my-auto">
                  {status}
                </Paragraph>
                <Button disabled={isSubmitted} className="signup-button mt-0" type="submit">
                  Sign Up
                </Button>
              </form>
            </div>
          </div>
        </Col>

        {/* Mobile */}
        <Col className="d-block d-lg-none">
          <div className="signup-container d-flex flex-column px-4 py-4">
            <Header className="fw-semibold" fontSize="30px" color="primary">
              <span className="muted">Frankly</span> Sign Up.
            </Header>
            <Paragraph size="p3" className="py-2 fw-medium">
              {/* Become a partner today! */}
              Shop mere med Frankly Insure.
            </Paragraph>
            <form className="signup-form" onSubmit={handleSignup}>
              <input
                className="signup-text-input"
                placeholder="Navn"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <input
                className="signup-text-input"
                placeholder="Email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <br />
              <input
                className="signup-checkbox"
                type="checkbox"
                checked={terms}
                onChange={(e) => setTerms(e.target.checked)}
              />
              <label className="py-3 ms-2 signup-checkbox-label" htmlFor="terms">
                Jeg giver samtykke{' '}
                <a className="p2" target="_blank" href="/terms">
                  {'(Betingelser og vilkår)'}
                </a>
              </label>
              <br />
              <Paragraph size="p3" className="paragraph-muted my-auto">
                {status}
              </Paragraph>
              <Button className="signup-button mt-0" type="submit">
                Sign Up
              </Button>
            </form>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default Signup
