import { Container, Row, Col } from 'react-bootstrap'
import Header from '../Header'
import Paragraph from '../Paragraph'
import { Link } from 'react-router-dom'

import './TermsSection.css'

const TermsSection = () => {
  return (
    <Container className="custom-container my-5">
      <Header fontSize="50px" color="primary" className="py-md-4 fw-semibold m-auto">
        {/* General terms */}
        Vilkår
      </Header>
      <Row className="my-5">
        <Col className="terms-container px-3 px-md-5 pb-5">
          <Row>
            {/* <TermsRow
              title="Introduction"
              content=
              {<>
                Welcome to Frankly Insure, a platform that simplifies insurance for both e-commerce platforms and their customers.
                <br/> By accessing our services, we assume you accept these terms and conditions. Do not continue to use Frankly Insure's services if you do not agree to take all the terms and conditions stated on this page.
              </>}
            /> */}
            <TermsRow
              title="Introduktion"
              content={
                <>
                  Velkommen til Frankly Insure. Vi har skabt en platform designet til at gøre forsikring enklere og
                  smartere for både butikker og kunder. Vores mål er, at levere Danmarks mest ligetil forsikring.{' '}
                </>
              }
            />
          </Row>
          <Row>
            {/* <TermsRow
              title="Services"
              content=
              {<>
                Welcome to Frankly Insure, a platform that simplifies insurance for both e-commerce platforms and their customers.
                <br/> By accessing our services, we assume you accept these terms and conditions. Do not continue to use Frankly Insure's services if you do not agree to take all the terms and conditions stated on this page.              
              </>}
            /> */}
            <TermsRow
              title="Services"
              content={
                <>
                  Ved at benytte Frankly Insure accepterer du de vilkår og betingelser, som er anført her og under{' '}
                  <Link target="_blank" className="text-decoration-underline" to="/conditions">
                    forsikringsbetingelser
                  </Link>
                  .
                  <br /> Hvis du ikke kan acceptere disse vilkår og betingelser, skal du ikke benytte Frankly Insures
                  tjenester.
                </>
              }
            />
          </Row>
          <Row>
            <TermsRow
              title="Berettigelse til dækning"
              content={
                <>
                  For at være berettiget til dækning skal følgende kriterier opfyldes:
                  <ol className="terms-list" type="a">
                    <li>Du skal være mindst 18 år ved køb af forsikringen.</li>
                    <li>
                      Forsikringen skal købes senest 30 dage efter køb af det pågældende udstyr der ønskes forsikret.
                    </li>
                    <li>Udstyret må ikke være bortkommet, stjålet eller beskadiget før forsikringens startdato.</li>
                    <li>
                      Du skal eje det udstyr, der forsikres, og det må ikke være købt brugt, på auktion eller fra en
                      online-auktionsside.
                    </li>
                    <li>Dit udstyr skal være købt nyt i Danmark.</li>
                    <li>
                      Forsikringsdækning af tilbehør kan kun tilkøbes, hvis du også har købt forsikring af det primære
                      udstyr.
                    </li>
                    <li>Din registrerede adresse skal være i Danmark.</li>
                    <li>Værdien af dit udstyr må ikke overstige den angivne værdi i din forsikringsoversigt.</li>
                  </ol>
                </>
              }
            />
          </Row>
          <Row>
            <TermsRow
              title="Fortrydelsesret"
              content={
                <>
                  Du kan fortryde dit køb af forsikring indenfor 14 dage fra købstidspunktet.
                  <br />
                  For at udøve fortrydelsesretten, kontakt os på{' '}
                  <a href="mailto:shopper@franklyinsure.com" className="text-decoration-underline">
                    shopper@franklyinsure.com
                  </a>{' '}
                  eller ring til +45 25 48 83 14.
                </>
              }
            />
          </Row>
          <Row>
            <TermsRow
              title="Betaling"
              content={
                <>
                  Du har købt en månedlig aftale. Din forsikring træder i kraft på købstidspunktet og løber i en periode
                  på en (1) måned. Den første måneds forsikring er gratis. Den vil derefter fortsætte i yderligere
                  månedlige perioder, forudsat at du fortsætter med at betale dine månedlige præmier, når de forfalder.
                </>
              }
            />
          </Row>
          <Row>
            <TermsRow
              title="Manglende betaling"
              content={
                <>
                  Hvis betalingen ikke kan trækkes, vil vi påminde dig via e-mail og SMS efter 3 dage. Du vil have 21
                  dage til at betale denne. Sker dette ikke vil du modtage 2 rykkere. I 2. rykker vil det fremgå at
                  forsikringen lukkes ned ved manglende betaling efter yderligere 21 dage.
                </>
              }
            />
          </Row>
          <Row>
            <TermsRow
              title="Opsigelse"
              content={
                <>
                  Som kunde har du ret til at opsige din forsikring hos Frankly Insure til enhver tid. Når du tegner en
                  police, er du dækket fra det præcise øjeblik og en måned frem. Policen og din dækning fornyes
                  automatisk hver måned ved korrekt indbetaling. Hvis du ønsker at opsige din forsikring, kan det gøres
                  nemt via vores{' '}
                  <Link className="text-decoration-underline" to="/contact" target="_blank">
                    kontaktside
                  </Link>{' '}
                  eller direkte gennem MobilePay. Ved opsigelse, vil din dækning fortsætte indtil den aktuelle
                  policeperiode udløber.
                </>
              }
            />
          </Row>
          <Row>
            <TermsRow
              title="Nuværende forsikringer"
              content={
                <>
                  Vær opmærksom på eventuelle eksisterende forsikringer, som dækker det samme som din Frankly
                  Insure-police.
                  <br />
                  Du kan enten selv opsige sådanne forsikringer ved at kontakte dit forsikringsselskab.
                </>
              }
            />
          </Row>
          <Row>
            <TermsRow
              title="Klagemuligheder"
              content={
                <>
                  Uenigheder eller klager kan rettes direkte til os via vores{' '}
                  <Link className="text-decoration-underline" to="/contact" target="_blank">
                    kontaktside
                  </Link>
                  . Under betingelser og i din police finder du også information om yderligere klageinstanser som Wakam
                  og Forsikringsklagenævnet.
                </>
              }
            />
          </Row>
          <Row>
            <TermsRow
              title="Tilsyn og Garantifond"
              content={
                <>
                  Frankly Insure overholder retningslinjerne fra Finanstilsynet,
                  <br />
                  og Wakam er medlem af Garantifonden for forsikringsselskaber.
                </>
              }
            />
          </Row>
          <Row>
            <TermsRow
              title="Godtgørelse"
              content={
                <>
                  Vores medarbejdere og partnere kan modtage godtgørelse, enten som fast løn eller provision, i
                  forbindelse med salg af forsikringer.
                </>
              }
            />
          </Row>
          <Row>
            <TermsRow
              title="Særlige tilbud"
              content={
                <>
                  Hvis du har benyttet dig af et særligt tilbud, f.eks. gratis dækning, forbeholder vi os retten til at
                  afslutte tilbuddet med 6 måneders varsel.
                </>
              }
            />
          </Row>

          {/* <Row>
            <TermsRow
              title="Cooling off Period"
              content=
              {<>
                You have a 14-day cooling-off period on the purchase of this insurance. The 14 days start from when you complete the purchase. You exercise your right to withdraw by sending an email to us at info@franklyinsure.com or by calling +45 25 48 83 14.              
              </>}
            />
          </Row>
          <Row>
            <TermsRow
              title="Payment"
              content=
              {<>
                Payment is drawn each month from the credit card you provide. If payment cannot be drawn, we will remind you via email and SMS. After the second reminder, we will give you a notice that the insurance will be terminated if you do not pay within 14 days. If the premium is not paid within the 14 days, the insurance agreement is terminated with 21 days notice. If you complete the payment within the 21 days, we cancel the termination of your insurance.              
              </>}
            />
          </Row>
          <Row>
            <TermsRow
              title="Current Insurances"
              content=
              {<>
                When purchasing insurance, please note if you are already covered by a similar insurance policy. You can grant Frankly Insure the authority to cancel it for you, otherwise, you must take care of cancelling any similar insurances with other insurance companies.              
              </>}
            />
          </Row>
          <Row>
            <TermsRow
              title="Non-Payment"
              content=
              {<>
                If payment cannot be drawn from your card, we will contact you via email and SMS. After the second reminder, we will give you a notice that the insurance will be terminated if you do not pay within 14 days. If the premium is not paid within the 14 days, the insurance agreement is terminated with 21 days notice. If you complete the payment within the 21 days, we cancel the termination of your insurance.              
              </>}
            />
          </Row>
          <Row>
            <TermsRow
              title="Special Offers"
              content=
              {<>
                If you have made use of a special offer, e.g., free coverage, we reserve the right to end the offer with a 6-month notice.              
              </>}
            />
          </Row>
          <Row>
            <TermsRow
              title="Complaints"
              content=
              {<>
                If you disagree with our decision, please contact Frankly Insure at www.franklyinsure.com. Here you can also read about how to proceed with the complaint to Wakam and the Insurance Complaints Board if necessary. If a dispute concerning the insurance agreement is brought before the Court, it is settled under Danish law by a Danish court and in other respects according to the rules of jurisdiction of the Administration of Justice Act.              
              </>}
            />
          </Row>
          <Row>
            <TermsRow
              title={<>Supervision & <br className="d-block d-sm-none"/> Guarantee Fund</>}
              content=
              {<>
                Frankly Insure is subject to supervision by the Financial Supervisory Authority and Wakam is affiliated with the Guarantee Fund for insurance companies.              
              </>}
            />
          </Row>
          <Row>
            <TermsRow
              title="Remuneration"
              content=
              {<>
                The Frankly Insure employees you speak with in connection with the purchase may be remunerated with a fixed salary or commission.              
              </>}
            />
          </Row> */}
        </Col>
      </Row>
    </Container>
  )
}

const TermsRow = ({ title, content }) => {
  return (
    <div className="terms-row">
      <div className="mt-5">
        <div className="white-circle d-inline-flex me-2" />
        <Header size="h3" color="primary" className="fw-semibold d-inline-flex">
          {title}
        </Header>
      </div>
      <Paragraph size="p3" color="primarytextcolor" className="paragraph-muted fw-light mb-0 pt-3">
        {content}
      </Paragraph>
    </div>
  )
}

export default TermsSection
