import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Header from '../Header'
import Paragraph from '../Paragraph'
import MaxWidthWrap from '../MaxWidthWrap'
import axios from 'axios'

import React, { useState, useEffect } from 'react'
import { Container, Dropdown, Form } from 'react-bootstrap'
import './Numbers.css'

const Numbers = () => {
  const [selected, setSelected] = useState({
    electronics: true,
    'home decor': false,
    'kitchen appliances': false,
    clothing: false,
    toys: false,
  })

  const [quantity, setQuantity] = useState(1500)

  const [price, setPrice] = useState(0)

  const handleQuantityChange = (event) => {
    let val = parseInt(event.target.value)
    if (val < 0 || isNaN(val)) val = 1500
    setQuantity(val)
  }

  // useEffect hook runs when `selected` state changes
  useEffect(() => {
    const data = {
      selected: selected,
      quantity: quantity,
    }

    axios
      .post(process.env.REACT_APP_API_URL + '/api/number', data)
      .then((res) => {
        setPrice(res.data.price)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [selected, quantity])

  return (
    <Container className="custom-container pt-5 my-5">
      <Header size="h4" color="primary" className="pt-3 pt-sm-5 text-center fw-light mt-sm-5 mb-2 mb-sm-5">
        {/* And if you want it <span className="fw-bold">in numbers.</span> */}
        <span className="fw-bold">Og hvis du foretrækker</span> at få det i tal.
      </Header>
      <Row className="d-none">
        <Col className="d-flex justify-content-center pt-3">
          <MaxWidthWrap width="1080px" className="">
            <div className="price-calc frankly-card d-block d-xl-flex flex-row flex-wrap py-5">
              <div className="product-types">
                <Paragraph size="p3" className="bold paragraph-muted">
                  Product Types
                </Paragraph>
                <MultiSelectDropdown selected={selected} setSelected={setSelected} />
              </div>
              <div className="pt-4 pt-md-0 quantity">
                <Paragraph size="p3" className="bold paragraph-muted">
                  Quantity sold pr. month
                </Paragraph>
                <input
                  onChange={handleQuantityChange}
                  className="quantity-input"
                  placeholder="1500"
                  type="number"
                  id="quantity"
                  name="quantity"
                />
                <br />
              </div>
              <div className="pt-4 mx-auto pt-md-0 payback d-flex flex-column justify-content-end align-items-center">
                <Paragraph size="p5" className="bold m-0 paragraph-muted">
                  {price},-
                </Paragraph>
                <Paragraph size="p3" className="paragraph-muted fw-light">
                  Est. Monthly kickback.
                </Paragraph>
              </div>
            </div>
          </MaxWidthWrap>
        </Col>
      </Row>
      <Row className="d-flex justify-content-center">
        <Col lg={4} className="numbers-col-wrapper">
          <div className="numbers-col frankly-card d-flex flex-column justify-content-between align-items-center">
            <div className="numbers-container d-flex justify-content-center align-items-center my-auto">
              <Header fontSize="40px" color="primary" className="fw-bold pt-3 text-center my-auto">
                20-45%
              </Header>
            </div>
            <div className="numbers-text white-card w-100 py-4 px-3">
              <Paragraph fontSize="20px" className="m-0 text-center paragraph-muted fw-light">
                {/* Is how much your average <br/> order value can increase. */}
                Er så meget din <br /> AOV kan stige
              </Paragraph>
            </div>
          </div>
        </Col>
        <Col lg={4} className="numbers-col-wrapper">
          <div className="numbers-col frankly-card d-flex flex-column justify-content-between align-items-center">
            <div className="numbers-container d-flex justify-content-center align-items-center my-auto">
              <Header fontSize="40px" color="primary" className="fw-bold pt-3 text-center my-auto">
                2-8%
              </Header>
            </div>
            <div className="numbers-text white-card w-100 py-4 px-3">
              <Paragraph fontSize="20px" className="m-0 text-center paragraph-muted fw-light">
                {/* Is how much your conversion <br/>rate can increase */}
                Er så meget din
                <br /> konverteringsrate kan stige
              </Paragraph>
            </div>
          </div>
        </Col>
        <Col lg={4} className="numbers-col-wrapper">
          <div className="numbers-col frankly-card d-flex flex-column justify-content-between align-items-center">
            <div className="numbers-container d-flex justify-content-center align-items-center my-auto">
              <Header fontSize="40px" color="primary" className="fw-bold pt-3 text-center my-auto">
                20%
              </Header>
            </div>
            <div className="numbers-text white-card w-100 py-4 px-3">
              <Paragraph fontSize="20px" className="m-0 text-center paragraph-muted fw-light">
                {/* Is how much your customer <br/> retention can increase */}
                Er så meget din fastholdelse <br /> af kunder kan stige
              </Paragraph>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default Numbers

function MultiSelectDropdown({ selected, setSelected }) {
  const handleSelect = (productType) => {
    setSelected((prevState) => ({
      ...prevState,
      [productType]: !prevState[productType],
    }))
  }

  return (
    <Dropdown className="numbers-dropdown">
      <Dropdown.Toggle className="p-0" variant="" id="dropdown-basic">
        {' '}
        {/* variant="success"*/}
        Select product types
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {Object.keys(selected).map((productType) => (
          <Dropdown.Item key={productType} as="button">
            <Form.Check
              type="checkbox"
              id={productType}
              label={productType.charAt(0).toUpperCase() + productType.slice(1)}
              checked={selected[productType]}
              onChange={() => handleSelect(productType)}
            />
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}
