import './Header.css'

const Header = ({
  size,
  children,
  className = '',
  color = 'primarytextcolor',
  bold = false,
  type = 'h2',
  fontSize,
}) => {
  let boldClass = bold ? 'bold' : ''
  //Map color to color
  const colorMap = {
    primarytextcolor: 'primarytextcolor',
    secondarytextcolor: 'secondarytextcolor',
    primary: 'primary-color',
    secondary: 'secondary-color',
    tertiary: 'tertiary-color',
  }
  let sizes = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'h7', 'logoheader', 'footer-header', 'price-header']
  if (sizes.includes(size))
    return (
      <h1 className={'header-component ' + size + ' ' + boldClass + ' ' + colorMap[color] + ' ' + className}>
        {children}
      </h1>
    )
  else if (fontSize)
    return (
      <h1
        className={'header-component ' + boldClass + ' ' + colorMap[color] + ' ' + className}
        style={{ fontSize: fontSize }}
      >
        {children}
      </h1>
    )
  else return <h1>No header size selected.</h1>
}

export default Header
