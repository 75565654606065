import NavBar from '../page-components/NavBar'
import Footer from '../page-components/Footer'
import ConditionExpandableSection from '../page-components/ConditionsPage/ConditionExpandableSection'

const Conditions = () => {
  return (
    <>
      <NavBar
        navArray={[
          { name: 'Hjem', link: '/' },
          { name: 'For butikker', link: '/business', active: true },
        ]}
      />

      <ConditionExpandableSection />

      <Footer />
    </>
  )
}

export default Conditions
