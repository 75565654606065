import { useEffect } from 'react'

const Redirect = ({ to = '/', delay = 0 }) => {
  useEffect(() => {
    setTimeout(() => {
      window.location.href = to
    }, delay)
  }, [to, delay])
}

export default Redirect
