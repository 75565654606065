import './Button.css'

import Paragraph from './Paragraph'

import { useEffect, useState } from 'react'

const Button = ({ children, onClick, className, signUp = false, ptag = true, disabled = false }) => {
  const [signUpDiv, setSignUpDiv] = useState(null)

  useEffect(() => {
    const id = document.getElementById('signup')
    setSignUpDiv(id)
  }, [])

  let classes = 'button ' + className
  if (signUp) {
    onClick = () => {
      signUpDiv.scrollIntoView({ behavior: 'smooth' })
      console.log(signUpDiv)
    }
    if (!children) children = 'Sign Up'
  }
  if (ptag)
    //Wrap children in p tag
    children = (
      <Paragraph className="fw-normal paragraph-secondary-color" size="p3">
        {children}
      </Paragraph>
    )
  if (disabled) classes += ' button-disabled'

  return (
    <button className={classes} onClick={onClick}>
      {children}
    </button>
  )
}

export default Button
