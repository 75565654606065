import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/black-and-white.css'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Header from '../Header'
import Paragraph from '../Paragraph'
import './Integration.css'
import Button from '../Button'

import Shopify from '../../img/Shopify.png'
import WooCommerce from '../../img/WooCommerce.png'
import Magento from '../../img/Magento.png'
import Squarespace from '../../img/Squarespace.png'
import Wix from '../../img/Wix.png'
import { Container } from 'react-bootstrap'

const Integration = () => {
  return (
    <Container className="custom-container mt-sm-7 pt-sm-3">
      <Header size="h4" className="fw-light text-center mt-6 pt-5">
        {/* Time is precious.<span className='fw-bold'> Integration is key.</span> */}
        <span className="fw-bold">Tid er penge.</span> Hurtig integration er alt.
      </Header>
      <Paragraph className="text-center fw-light pt-5" size="p3">
        {/* Enjoy our 100% plug-n-play solution. <br/>
        Our technicians will implement the system within 1 hour. <br/>
        The cost? A signup fee of 1.995 DKK.   */}
        Nyd vores 100% plug-n-play løsning. <br />
        Med vores teknikere kan din løsning være klar på 1-2 timer. <br />
        Prisen? Et opstartsgebyr på 1.995 DKK.
      </Paragraph>
      <Row className="pt-sm-5">
        <Col className="">
          <div className="integration-row pe-2">
            <div className="integration-logo-wrap frankly-card">
              <LazyLoadImage
                alt={'Shopify'}
                effect="black-and-white"
                src={Shopify}
                className="integration-logo"
                height="110px"
              />
            </div>
            <div className="integration-logo-wrap frankly-card">
              <LazyLoadImage
                alt={'WooCommerce'}
                effect="black-and-white"
                src={WooCommerce}
                className="integration-logo"
                height="110px"
              />
            </div>
            <div className="integration-logo-wrap frankly-card">
              <LazyLoadImage
                alt={'Magento'}
                effect="black-and-white"
                src={Magento}
                className="integration-logo pt-4"
                height="150px"
              />
            </div>
            <div className="integration-logo-wrap frankly-card">
              <LazyLoadImage
                alt={'Squarespace'}
                effect="black-and-white"
                src={Squarespace}
                className="integration-logo"
                height="100px"
              />
            </div>
            <div className="integration-logo-wrap frankly-card">
              <LazyLoadImage
                alt={'Wix'}
                effect="black-and-white"
                src={Wix}
                className="integration-logo"
                height="90px"
              />
            </div>
          </div>
        </Col>
      </Row>
      <span className="d-flex justify-content-center pt-5">
        <Button onClick={() => (window.location.href = '#signup')}>
          {/* Get me started */}
          Bliv kontaktet
        </Button>
      </span>
    </Container>
  )
}

export default Integration
