import { Col, Container, Row } from 'react-bootstrap'
import './ClaimsForm.css'
import Header from '../../Header'
import ClaimsInput from './ClaimsInput'
import Button from '../../Button'
import { useState } from 'react'
import axios from 'axios'
import Accordion from 'react-bootstrap/Accordion'
import Paragraph from '../../Paragraph'

const ClaimsForm = ({ errorMessages, setErrorMessages }) => {
  // State to manage the form fields, status message and more
  const [states, setStates] = useState({
    receiptOpen: false,
    homeAddress: false,
    fileInputs: {},
    inputs: {},
    status: '',
    isSubmitted: false,
    essentialFields: [
      'Name',
      'Email',
      'Phone',
      'Address',
      'City',
      'Zip',
      'HowAndWhy',
      'DamageLocation',
      'DamageEffect',
      'Model',
      'TimeOfIncident',
      'PolicyNumber',
      'IncidentAddress',
      'FileReceipt',
      'FileProduct',
      'OtherInsurance',
    ],
  })

  // Function to toggle boolean state values and handle change for sliders
  const toggleState = (stateName, e) => {
    setStates((prev) => {
      const updatedState = { ...prev, [stateName]: !prev[stateName] }

      // If the user selects "home address is same as incident location"
      if (stateName === 'homeAddress' && updatedState.homeAddress) {
        updatedState.essentialFields = updatedState.essentialFields.filter(
          (field) => !['IncidentAddress'].includes(field),
        )
      } else if (stateName === 'homeAddress' && !updatedState.homeAddress) {
        updatedState.essentialFields = [...updatedState.essentialFields, 'IncidentAddress']
      }

      // If the user lost the receipt
      if (stateName === 'receiptOpen' && updatedState.receiptOpen) {
        // remove FileReceipt from essential fields and add ProductPrice fields
        updatedState.essentialFields = updatedState.essentialFields.filter((field) => field !== 'FileReceipt')
        updatedState.essentialFields = [
          ...updatedState.essentialFields,
          'ProductPrice',
          'ProductPlaceOfPurchase',
          'ProductTimeOfPurchase',
          'ProductDescription',
        ]
      } else if (stateName === 'receiptOpen' && !updatedState.receiptOpen) {
        // remove product fields and re-add FileReceipt
        updatedState.essentialFields = updatedState.essentialFields.filter(
          (field) =>
            !['ProductPrice', 'ProductPlaceOfPurchase', 'ProductTimeOfPurchase', 'ProductDescription'].includes(field),
        )
        if (!updatedState.essentialFields.includes('FileReceipt')) {
          updatedState.essentialFields.push('FileReceipt')
        }
      }

      return updatedState
    })

    handleChange(e)
  }

  const handleChange = (e) => {
    const { name, value, files } = e.target
    if (files) {
      // Handle file input change
      setStates((prev) => ({
        ...prev,
        fileInputs: {
          ...prev.fileInputs,
          [name]: files,
        },
      }))
    } else {
      // Handle standard input change
      setStates((prev) => ({
        ...prev,
        inputs: {
          ...prev.inputs,
          [name]: value,
        },
      }))
    }
  }

  const validateForm = () => {
    let errors = {}
    for (let field of states.essentialFields) {
      const noTextValue = !states.inputs[field] || states.inputs[field] === ''
      const noFileValue = !states.fileInputs[field] || states.fileInputs[field].length === 0
      if (noTextValue && noFileValue) {
        errors = {
          ...errors,
          [field]: `${field.replace(/([A-Z])/g, ' $1').trim()} skal udfyldes`,
        }
      }
    }
    setErrorMessages(errors)
    return Object.entries(errors).length === 0
  }

  const prepareFNOLData = () => {
    // Combine the damage description fields into one explanation
    const explanation = [
      `Hvordan og hvorfor er skaden sket?: ${states.inputs.HowAndWhy || ''}`,
      `Hvor er genstanden beskadiget?: ${states.inputs.DamageLocation || ''}`,
      `Hvordan påvirker skaden genstanden?: ${states.inputs.DamageEffect || ''}`,
    ].join('\n\n')

    // Construct the FNOL object
    const fnolData = {
      ...states.inputs,
      Home: states.homeAddress ? 'true' : 'false',
      Explanation: explanation,
      Theft: states.inputs.Theft ? 'true' : 'false',
      Receipt: states.receiptOpen ? 'true' : 'false',
      CreatedAt: new Date().toISOString(),
    }

    return fnolData
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (states.isSubmitted) {
      return
    }

    if (!validateForm()) {
      return
    }
    setStates((prev) => ({ ...prev, isSubmitted: true }))

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
      },
      withCredentials: false,
    }

    const requestData = new FormData()

    // Add files to FormData
    for (let key in states.fileInputs) {
      const files = states.fileInputs[key]
      if (files && files.length > 0) {
        Array.from(files).forEach((file) => {
          requestData.append(key, file)
        })
      }
    }

    // Prepare and append the FNOL data
    const fnolData = prepareFNOLData()
    requestData.append('json', JSON.stringify(fnolData))

    const form = e.target.closest('form')
    axios
      .post(`${process.env.REACT_APP_API_URL}/v1/claims/create-claim`, requestData, config)
      .then(() => {
        setStates((prev) => ({
          ...prev,
          status: (
            <span className="status-green">
              Tak for din anmeldelse. Vi behandler din sag og vender tilbage til dig hurtigst muligt.
            </span>
          ),
          inputs: {},
          fileInputs: {},
          isSubmitted: false,
        }))
        form.reset()
      })
      .catch((ex) => {
        console.error(ex)
        setStates((prev) => ({
          ...prev,
          status: 'Noget gik galt. Prøv igen.',
          isSubmitted: false,
        }))
      })
  }

  return (
    <Container className="my-md-5 my-2 custom-container position-relative" style={{ zIndex: 999 }}>
      <Row className="pt-3">
        <Col>
          <div className="claims-form py-md-5 px-md-4 px-2 py-4">
            <Row>
              <Col>
                <Header size="h4" className="fw-medium text-center">
                  Anmeld din skade her
                </Header>
              </Col>
            </Row>
            <Row>
              <Col>
                <Accordion defaultActiveKey={['0', '1', '2']} alwaysOpen>
                  <form className="p-md-4 p-3" onSubmit={handleSubmit}>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header bsPrefix="accordion-header-button">
                        <Col>
                          <Header size="h3" className="my-4">
                            Personoplysninger
                          </Header>
                          <Paragraph size="p3" className="fw-lighter mt-2">
                            Udfyld dine oplysninger, så vi kan kontakte dig.
                          </Paragraph>
                        </Col>
                      </Accordion.Header>
                      <Accordion.Body>
                        <Row>
                          <Col lg={6} sm={12}>
                            <ClaimsInput
                              essential
                              name="Fulde navn"
                              inputName="Name"
                              placeholder="Jens Jensen"
                              submission={handleChange}
                              error={Object.keys(errorMessages).includes('Name')}
                            />
                          </Col>
                          <Col lg={6} sm={12}>
                            <ClaimsInput
                              essential
                              name="Email"
                              inputName="Email"
                              placeholder="jens_jensen@gmail.com"
                              submission={handleChange}
                              error={Object.keys(errorMessages).includes('Email')}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={6} sm={12}>
                            <ClaimsInput
                              essential
                              name="Telefonnummer"
                              inputName="Phone"
                              placeholder="(+45) 31424729"
                              submission={handleChange}
                              error={Object.keys(errorMessages).includes('Phone')}
                            />
                          </Col>
                          <Col lg={6} sm={12}>
                            <ClaimsInput
                              essential
                              name="Adresse"
                              inputName="Address"
                              placeholder="Østerbrogade 21, 2tv"
                              submission={handleChange}
                              error={Object.keys(errorMessages).includes('Address')}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={6} sm={12}>
                            <ClaimsInput
                              essential
                              name="By"
                              inputName="City"
                              placeholder="København Ø"
                              submission={handleChange}
                              error={Object.keys(errorMessages).includes('City')}
                            />
                          </Col>
                          <Col lg={6} sm={12}>
                            <ClaimsInput
                              essential
                              name="Postnummer"
                              inputName="Zip"
                              placeholder="2100"
                              submission={handleChange}
                              error={Object.keys(errorMessages).includes('Zip')}
                            />
                          </Col>
                        </Row>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header bsPrefix="accordion-header-button">
                        <Col>
                          <Header size="h3" className="my-4">
                            Produktinformation
                          </Header>
                          <Paragraph size="p3" className="fw-lighter mt-2">
                            Fortæl os om den forsikrede genstand.
                          </Paragraph>
                        </Col>
                      </Accordion.Header>
                      <Accordion.Body>
                        <Row>
                          <Col lg={6} sm={12}>
                            <ClaimsInput
                              essential
                              name="Specifik model (inkl. GB størrelse, farve)"
                              inputName="Model"
                              placeholder="For eksempel: iPhone 15 Pro Max, 256GB, Space Black"
                              submission={handleChange}
                              error={Object.keys(errorMessages).includes('Model')}
                            />
                          </Col>
                          <Col lg={6} sm={12}>
                            <ClaimsInput
                              name="Serienummer"
                              inputName="SN"
                              placeholder="For eksempel: W88401231AX eller C02CG123DC79"
                              submission={handleChange}
                              error={Object.keys(errorMessages).includes('SN')}
                            />
                          </Col>
                        </Row>
                        <ClaimsInput
                          name="Har du mistet din kvittering?"
                          inputName="Receipt"
                          placeholder="Ja"
                          type="slider"
                          toggle={(e) => toggleState('receiptOpen', e)}
                        />
                        {/* If user has NOT lost receipt, show FileReceipt */}
                        <div className={!states.receiptOpen ? '' : 'd-none'}>
                          <ClaimsInput
                            essential
                            name="Upload kvittering for produktet"
                            inputName="FileReceipt"
                            placeholder="Klik for at vælge fil - Ingen fil valgt"
                            type="file"
                            submission={handleChange}
                            error={Object.keys(errorMessages).includes('FileReceipt')}
                          />
                        </div>
                        {/* If user HAS lost receipt, show extended product info */}
                        <div className={states.receiptOpen ? '' : 'd-none'}>
                          <ClaimsInput
                            essential
                            name="Prisen på produktet"
                            inputName="ProductPrice"
                            placeholder="For eksempel: 2999 kr"
                            submission={handleChange}
                            error={Object.keys(errorMessages).includes('ProductPrice')}
                          />
                          <Row>
                            <Col lg={6} sm={12}>
                              <ClaimsInput
                                essential
                                name="Hvor er produktet købt"
                                inputName="ProductPlaceOfPurchase"
                                placeholder="Firmanavn eller link til hjemmeside"
                                submission={handleChange}
                                error={Object.keys(errorMessages).includes('ProductPlaceOfPurchase')}
                              />
                            </Col>
                            <Col lg={6} sm={12}>
                              <ClaimsInput
                                essential
                                name="Hvornår er produktet købt?"
                                inputName="ProductTimeOfPurchase"
                                placeholder="Tid - DD/MM/ÅÅÅÅ"
                                submission={handleChange}
                                error={Object.keys(errorMessages).includes('ProductTimeOfPurchase')}
                              />
                            </Col>
                          </Row>
                          <ClaimsInput
                            essential
                            name="Beskrivelse af produktet"
                            inputName="ProductDescription"
                            placeholder="For eksempel: iPhone 15 Pro Max, 256GB, Space Black    "
                            type="textarea"
                            submission={handleChange}
                            error={Object.keys(errorMessages).includes('ProductDescription')}
                          />
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header bsPrefix="accordion-header-button">
                        <Col>
                          <Header size="h3" className="my-4">
                            Oplysninger om skaden
                          </Header>
                          <Paragraph size="p3" className="fw-lighter mt-2">
                            Beskriv skaden så detaljeret som muligt, så vi hurtigt kan hjælpe dig!
                          </Paragraph>
                        </Col>
                      </Accordion.Header>
                      <Accordion.Body>
                        <ClaimsInput
                          essential
                          name="Skadessted"
                          inputName="IncidentAddress"
                          placeholder="For eksempel: Strøget 12, 1160 København K eller på Strøget"
                          submission={handleChange}
                          error={Object.keys(errorMessages).includes('IncidentAddress')}
                        />

                        <ClaimsInput
                          essential
                          name="Hvordan og hvorfor er skaden sket?"
                          inputName="HowAndWhy"
                          placeholder="For eksempel: Jeg havde min telefon i hånden og idet jeg skal gå ned af nogle trapper på mit arbejde, snubler jeg og telefonen ryger derfor ud af hånden og lander på asfalten med frontskærmen nedad."
                          type="textarea"
                          submission={handleChange}
                          error={Object.keys(errorMessages).includes('HowAndWhy')}
                        />

                        <ClaimsInput
                          essential
                          name="Hvor er genstanden beskadiget?"
                          inputName="DamageLocation"
                          placeholder="For eksempel: Frontskærmen er splintret øverst i højre side og frontkameraet har også en revne."
                          type="textarea"
                          submission={handleChange}
                          error={Object.keys(errorMessages).includes('DamageLocation')}
                        />

                        <ClaimsInput
                          essential
                          name="Hvordan påvirker skaden genstanden?"
                          inputName="DamageEffect"
                          placeholder="For eksempel: Skærmen er sort i det område hvor den er splintret og hverken touchskærmen eller frontkamera virker mere."
                          type="textarea"
                          submission={handleChange}
                          error={Object.keys(errorMessages).includes('DamageEffect')}
                        />

                        <ClaimsInput
                          name="Har du været udsat for tyveri?"
                          inputName="Theft"
                          placeholder="Ja"
                          type="slider"
                          submission={handleChange}
                        />
                        <Row>
                          <Col lg={6} sm={12}>
                            <ClaimsInput
                              essential
                              name="Hvornår skete skaden?"
                              inputName="TimeOfIncident"
                              placeholder="Tid - DD/MM/ÅÅÅÅ"
                              submission={handleChange}
                              error={Object.keys(errorMessages).includes('TimeOfIncident')}
                            />
                          </Col>
                          <Col lg={6} sm={12}>
                            <ClaimsInput
                              essential
                              name="Policenummer"
                              inputName="PolicyNumber"
                              placeholder="100000001"
                              submission={handleChange}
                              error={Object.keys(errorMessages).includes('PolicyNumber')}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={6} sm={12}>
                            <ClaimsInput
                              essential
                              name="Andre relevante forsikringer"
                              inputName="OtherInsurance"
                              placeholder="For eksempel: Indbo og ulykkesforsikring"
                              submission={handleChange}
                              error={Object.keys(errorMessages).includes('OtherInsurance')}
                            />
                          </Col>
                          <Col lg={6} sm={12}>
                            <ClaimsInput
                              essential
                              name="Upload billeder eller video af skaden"
                              inputName="FileProduct"
                              placeholder="Klik for at vælge fil - Ingen fil valgt"
                              type="file"
                              multiple={true}
                              submission={handleChange}
                              error={Object.keys(errorMessages).includes('FileProduct')}
                            />
                          </Col>
                        </Row>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Button className="float-end mr-5 mt-5" disabled={states.isSubmitted}>
                      Anmeld skade
                    </Button>
                    <p className="w-100 text-center mt-3 px-md-5 d-inline-block">{states.status}</p>
                  </form>
                </Accordion>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default ClaimsForm
